import React from "react";
// import { Fade } from "react-reveal";
import "./ProjectCard.scss";

export default function ProjectCard(props) {
  const split_tools = [
    "Machine-Learning",
    "Neural-Networks",
    "Android-SDK",
    "Computer-Vision",
    "Sentiment-Analysis",
    "Text-Classification",
    "Data-Pipeline",
    "Big-Data",
    "Ruby-on-Rails",
    "Vue-JS",
  ];

  return (
    <>
    {/* <Fade bottom duration={2000} distance="40px"> */}
      <div class="blog-card-wrapper">
        <div>
          <div class={"blog-card " + props.project.bg_img_type}>
            <div class="content-mask">
              <div style={{ marginTop: "20px" }}>
                {props.project.tools.map((tool) => (
                  <span class={"category " + tool.toLowerCase()}>
                    {split_tools.includes(tool)
                      ? tool.split("-").join(" ")
                      : tool}
                  </span>
                ))}
              </div>
              <h5 style={{fontSize: "1.1em", color: "white"}}><strong>{props.project.title}</strong></h5>
              <p style={{fontSize: "0.95em", color: "#b3c5d1"}}>{props.project.description}</p>
              <div class="post-detail">
                <span class="icon" style={{color: "#b3c5d1"}}>
                  <svg
                    class="svg-inline--fa fa-calendar-alt fa-w-14"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="calendar-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
                    ></path>
                  </svg>
                </span>
                <span class="date" style={{color: "#b3c5d1"}}>{props.project.timeline}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* </Fade> */}
    </>
  );
}
