import React from "react";
import { Col, Row } from "react-bootstrap";
import { SWETools } from "../portfolio";

function SWEStack() {
  return (
    <div style={{ padding: "1.25rem" }}>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        {SWETools.map((tool) => {
          return (
            <Col xs={4} md={2} className="tech-icons">
              {tool.icon}
              <div class="tech-icons-text">{tool.title}</div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default SWEStack;
