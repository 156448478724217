// Image Imports
// Home Page
import ai_png from "../Assets/ai.png";
import swe_png from "../Assets/swe.png";
import cloud_png from "../Assets/cloud.png";

// Experience Page
// Companies
import access_logo from "../Assets/access_logo.png";
import shopee_logo from "../Assets/shopee_logo.png";
import onloop_logo from "../Assets/onloop_logo.jpg";
import hi_mart_logo from "../Assets/hi_mart_logo.png";
import ey_logo from "../Assets/ey_logo.png";
import alt_ey_logo from "../Assets/alt_ey_logo.png";
import gh_logo from "../Assets/gh_logo.png";

// Logo Imports
// Data Science Stack
import { Icon } from "@iconify/react";
// SWE Stack
import {
  DiHtml5,
  DiCss3,
  DiJavascript1,
  DiJava,
  DiRuby,
  DiReact,
  DiNodejs,
  DiDjango,
  DiAndroid,
  DiRedis,
  DiGit,
  DiSpark,
  DiPython,
} from "react-icons/di";
// Cloud Stack
import {
  SiAmazonaws,
  SiGooglecloud,
  SiHeroku,
  SiFirebase,
  SiPostgresql,
  SiMongodb,
  SiDocker,
  SiKubernetes,
  SiTypescript,
  SiFlask,
  SiVuedotjs,
  SiPytorch,
  SiTensorflow,
  SiKeras,
} from "react-icons/si";

// Home page
export const HomePage = {
  sections: [
    {
      title: "Data Science & AI",
      description: [
        "Develop highly scalable production-ready models for various Deep Learning and statistical use cases.",
        "Work on various Computer Vision (CV) and Natural Language Processing (NLP) use cases.",
        "Utilize complex quantitative modelling for dynamic forecasting and time series analysis.",
      ],
      image: ai_png,
      image_width: "80%",
    },
    {
      title: "Software Engineering",
      description: [
        "Build resposive websites using React.js, Next.js and Vue.js.",
        "Develop mobile applications using React Native and native andorid apps using the Android SDK.",
        "Create RESTful endpoints using backend frameworks such as Node.js, Flask, Django and Ruby on Rails.",
        "Set up task queues as well as caching systems using Redis.",
      ],
      image: swe_png,
      image_width: "90%",
    },
    {
      title: "Cloud Services",
      description: [
        "Work on multiple cloud platforms such as AWS and GCP.",
        "Deploy ML and DL models on the cloud to facilitate use on mobile devices.",
        "Utilize docker and containerisation services to deploy apps rapidly that scale with ease.",
        "Set up streaming jobs from DB to Server or vice-versa on GCP and AWS.",
      ],
      image: cloud_png,
      image_width: "100%",
    },
  ],
};

// About Page
export const coursework = [
  {
    title: "Software Modules",
    courses: [
      "The Digital World (Python)",
      "Information Systems and Programming (Java, Android)",
      "Elements of Software Construction (JavaScript, Testing)",
      "Service Design Studio (Ruby, SaaS, Cloud Services)",
      "Distributed Systems and Computing (Golang, Docker)",
    ],
  },
  {
    title: "Data Modules",
    courses: [
      "Algorithms",
      "Probability and Statistics",
      "Machine Learning",
      "Deep Learning",
      "Artificial Intelligence",
    ],
  },
  {
    title: "Hardware Modules",
    courses: ["Computational Structures", "Computer Systems Engineering"],
  },
  {
    title: "Finance / Strategy Modules",
    courses: ["Fundamentals of Investing", "Game Theory"],
  },
];

export const DataScienceTools = [
  { title: "TensorFlow", icon: <SiTensorflow /> },
  { title: "Keras", icon: <SiKeras /> },
  { title: "PyTorch", icon: <SiPytorch /> },
  { title: "Spark", icon: <DiSpark /> },
  {
    title: "Hadoop",
    icon: <Icon icon="grommet-icons:hadoop" style={{ color: "white" }} />,
  },
  {
    title: "Airflow",
    icon: <Icon icon="cib:apache-airflow" style={{ color: "white" }} />,
  },
  { title: "Python", icon: <DiPython /> },
];

export const SWETools = [
  { title: "HTML", icon: <DiHtml5 /> },
  { title: "CSS", icon: <DiCss3 /> },
  { title: "JavaScript", icon: <DiJavascript1 /> },
  { title: "TypeScript", icon: <SiTypescript /> },
  { title: "React", icon: <DiReact /> },
  { title: "Vue", icon: <SiVuedotjs /> },
  { title: "Android", icon: <DiAndroid /> },
  { title: "Java", icon: <DiJava /> },
  { title: "Ruby", icon: <DiRuby /> },
  { title: "Node.js", icon: <DiNodejs /> },
  { title: "Django", icon: <DiDjango /> },
  { title: "Flask", icon: <SiFlask /> },
  { title: "Redis", icon: <DiRedis /> },
];

export const CloudTools = [
  { title: "AWS", icon: <SiAmazonaws /> },
  { title: "GCP", icon: <SiGooglecloud /> },
  { title: "Heroku", icon: <SiHeroku /> },
  { title: "Firebase", icon: <SiFirebase /> },
  { title: "PostgreSQL", icon: <SiPostgresql /> },
  { title: "MongoDB", icon: <SiMongodb /> },
  { title: "Docker", icon: <SiDocker /> },
  { title: "Kubernetes", icon: <SiKubernetes /> },
  { title: "Git", icon: <DiGit /> },
];

// Experience Page
export const experience = [
  {
    title: "Work Experiences",
    last_section: false,
    experiences: [
      {
        company: "Access",
        time: "Aug 2021 - Mar 2022",
        title: "Co-Founder & Tech Lead",
        description:
          "At Access, I implemented the micro-services architecture to decouple the blockchain and the API layer and also set up a redis queue and incorporated threading to process multiple transactions at the same time. ",
        link: "https://accessapp.io/",
        image: access_logo,
      },
      {
        company: "Grasshopper",
        time: "May 2022 - Present",
        title: "Backend / DeFI Engineer",
        description:
          "At Grasshopper, I worked on developing the backend endpoints required to retrieve data from the blockchain and crypto exchanges so as to develop a crypto portfolio aggregator. ",
        link: "https://grasshopperasia.com/",
        image: gh_logo,
      },
      {
        company: "Shopee",
        time: "May 2021 - Nov 2021",
        title: "Data Scientist",
        description:
          "At Shopee, I was involved in building Named Entity Recognition (NER) models, Learning to Rank (LTR) models, as well as experimenting with model ensembling for various NLP use cases.",
        link: "https://careers.shopee.sg/about/",
        image: shopee_logo,
      },
      {
        company: "Onloop",
        time: "Mar 2021 - May 2021",
        title: "Software Engineer",
        description:
          "At Onloop, I worked on developing backend endpoints using Node.js (TypeScript). I was also involved in the set up of the Apollo Client (GraphQL) for the state management of the admin portal.",
        link: "https://www.onloop.com/",
        image: onloop_logo,
      },
      {
        company: "HiMart",
        time: "Dec 2019 - Feb 2020",
        title: "Fullstack Engineer",
        description:
          "At HiMart, I was involved in the development of new features such as providing users with recommendations based on past purchases using Vue.js for the frontend and Spring Boot (Java) for the backend.",
        link: "https://www.himartsingapore.com/",
        image: hi_mart_logo,
      },
      {
        company: "Ernst & Young (EY)",
        time: "May 2019 - Dec 2019",
        title: "Data Scientist",
        description:
          "At EY, I worked with OCR models and built object detection models using the OpenCV library to extract both strctured and unstructured data from financial docuemnts to automate the core business processes of the client.",
        link: "https://www.ey.com/en_sg",
        image: alt_ey_logo,
        alt_image: ey_logo,
      },
    ],
  },
];

// Projects Page
export const projects = [
  {
    section_title: "Data Science",
    projects: [
      {
        tools: ["Sentiment-Analysis", "Text-Classification"],
        bg_img_type: "data-science",
        title: "[Data] Opinions.ai", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Working with MinDef to build a SaaS that performs topic modelling to classify articles into different buckets and performs sentiment analysis (using the ASTE model) on the same to detrmine the sentiment of the public towards each cluster.",
        timeline: "Sep 2021 - Dec 2021",
      },
      {
        tools: ["Time Series Analysis", "Data-Pipeline"],
        bg_img_type: "data-science",
        title: "Cryptocurrency Exchange Tracker", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Leveraged the Google Cloud Platform to track cryptocurrency trades from globally scattered exchanges such as BitFinex, Binance etc. in real time and predict future trends of prices in microseconds by perfroming time series analysis.",
        timeline: "Mar 2020 - Apr 2020",
      },
      {
        tools: ["Time Series Analysis", "Firestore"],
        bg_img_type: "data-science",
        title: "Food Court Occupancy Tracker", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Utilized numerous IR sensors to collect data corresponding to the occupancy rate of the food court at different times and trained a time series forecasting model on the collected data to predict occpancy of the food court in the future.",
        timeline: "Jan 2020 - Mar 2020",
      },
      {
        tools: ["MATLAB", "Big-Data", "Statistics"],
        bg_img_type: "data-science",
        title: "Optimizing Dimensionality Reduction", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Developed Hashing Algorithms and experimented with different estimators with Conditional Random Sampling (CRS) to optimize dimensionality reduction i.e.) reduce the space complexity that occurs when working with big data.",
        timeline: "Sep 2019 - Feb 2020",
      },
      {
        tools: ["Neural-Networks", "RNN", "LSTM"],
        bg_img_type: "data-science",
        title: "Music Generator", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Developed a Continuous Recurrent Neural Network with adverserial training (C-RNN-GAN) and implemented the Long Short Term Memory (LSTM) architecture to develop a deep learning model that could produce random and short notes of music.",
        timeline: "Sep 2019 - Dec 2019",
      },
    ],
  },
  {
    section_title: "Software Engineering",
    projects: [
      {
        tools: ["React", "Ruby-on-Rails", "GCP", "Firestore"],
        bg_img_type: "web",
        title: "[SWE] Opinions.ai", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Working with MinDef to build a tool to understand public opinions through an interactive dasboard that uses React for the frontend, Ruby on Rails for the backend and GCP to run serverless functions that don't need to be run very often.",
        timeline: "Sep 2021 - Dec 2021",
      },
      {
        tools: ["React", "HTML", "CSS"],
        bg_img_type: "web",
        title: "Portfolio Website", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "The very website that you are on now! This portfolio website documents my journey in the world of Data Science and Software Engineering. It is a Single-page Application (SPA) that was developed using React, HTML and a whole lot of styling.",
        timeline: "Oct 2021 - Nov 2021",
      },
      {
        tools: ["React", "NodeJs", "PostgreSQL", "AWS"],
        bg_img_type: "web",
        title: "Audit-n-go", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Worked with SingHealth to digitally transform the process of conducting audits by developing a web application using React, Node.js and PostgreSQL and leveraged AWS to store images (S3 buckets) and deploy the application (EC2).",
        timeline: "Jan 2021 - Apr 2021",
      },
      {
        tools: ["React", "NodeJS", "NoSQL", "Heroku"],
        bg_img_type: "web",
        title: "Stock Control", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Designed and developed a web application and created RESTful APIs using MongoDB, Express, Node.js and React (the MERN stack) to aid clients with Inventory Management and deployed the web-based application on Heroku.",
        timeline: "Oct 2020 - Dec 2021",
      },
      {
        tools: ["Vue-JS", "Python", "Django", "PostgreSQL"],
        bg_img_type: "web",
        title: "Job Aggregator", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Built a web application using Vue.js that would scrape through job search websites for certain keywords and display the same in a tabulated manner. Aditionally, I also set up a cron job that would scrape the websites on a periodic basis.",
        timeline: "Sep 2020 - Dec 2020",
      },
      {
        tools: ["Android-SDK", "Flask", "NoSQL", "OOP"],
        bg_img_type: "web",
        title: "Plent (Smart Event Planner)", // Max length of a single line title
        description:
          // Max length of description for a single line decription
          // Will have decrease length of description if we are to have multi-line title
          "Developed Plent (the one stop school events app for students and organizers), an android based mobile applcation using the Android SDK, Flask and MongoDB (NoSQL) all while following the Object Oriented Programming (OOP) paradigm.",
        timeline: "Sep 2020 - Dec 2020",
      },
    ],
  },
];
