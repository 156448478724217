import React from "react";
import { Container } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import { projects } from "../portfolio";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p className="project-screen-desc" style={{ color: "white" }}>
          My projects act as a gatweway for me to tinker with the latest tools
          across different domains. Through my projects, I have had the
          opportunity to use tools across fields such as Data Science, Software
          Engineering and Cloud Computing.
        </p>
        {projects.map((project_section) => {
          return (
            <>
              <br />
              <br />
              <h1 className="project-heading">
                <strong className="purple">
                  {project_section.section_title}
                </strong>
              </h1>
              {project_section.projects.map((project) => (
                <div style={{ display: "inline-block" }} key={project.title}>
                  <ProjectCard project={project} />
                </div>
              ))}
            </>
          );
        })}
      </Container>
    </Container>
  );
}

export default Projects;
