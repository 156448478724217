import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
            I am a
            <strong className="purple">
              &nbsp;Computer Science Graduate&nbsp;
            </strong>
            with specializations in
            <strong className="purple">
              &nbsp; FinTech, Artificial Intelligence (AI) &nbsp;
            </strong>
            & <strong className="purple">Software Engineering </strong>
            from the
            <strong className="purple">
              &nbsp;Singapore University of Technology and Design&nbsp;
            </strong>
            (SUTD).
            <br />
            <br />
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
