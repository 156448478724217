import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataScienceStack from "./DataScienceStack";
import SWEStack from "./SWEStack";
import CloudStack from "./CloudStack";
import Aboutcard from "./AboutCard";
import sutd_png from "../../Assets/sutd_logo.png";

function About() {
  return (
    <Container fluid className="about-section" style={{ textAlign: "None" }}>
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Education
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <div
              id="img-div"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={sutd_png}
                alt="about"
                className="img-fluid small-screen-disappear"
                width="30%"
              />
              <br />
            </div>
          </Col>
        </Row>
        <h1 className="project-heading">
          <span className="purple">Data Science </span> skill set
        </h1>
        <DataScienceStack />

        <h1 className="project-heading">
          <span className="purple">Software Engineering </span> skill set
        </h1>
        <SWEStack />

        <h1 className="project-heading">
          <span className="purple">Cloud Services </span> skill set
        </h1>
        <CloudStack />
      </Container>
    </Container>
  );
}

export default About;
