import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Data Scientist",
          "Software Engineer",
          "Cloud Computing Enthusiast",
          "Co-Founder @ Access",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
        delay: 75
      }}
    />
  );
}

export default Type;
