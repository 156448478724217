import React from "react";
import { Container } from "react-bootstrap";
import { experience } from "../portfolio";
import ExperienceCard from "./ExperienceCard";

function Experience() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Work </strong>
          Experience
        </h1>
        <br />
        <p className="project-screen-desc" style={{ color: "white" }}>
          I am an ardent Data Scientist and Software Engineer with a
          demonstrated history of working in the Consulting, Venture Capital,
          Startup, and eCommerce space.
        </p>
        {experience.map((experience_section) => {
          return (
            <div key={experience_section.title}>
              <br />
              <br />
              {experience_section.experiences.map((experience) => (
                <div
                  style={{ display: "inline-block", margin: "auto" }}
                  key={experience.title}
                >
                  <div style={{ padding: "20px" }}>
                    <ExperienceCard experience={experience} />
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </Container>
    </Container>
  );
}

export default Experience;
