import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiFillMail, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { HomePage } from "../portfolio";

function Home2() {
  return (
    <Container
      fluid
      className="home-about-section"
      id="about"
      style={{ paddingTop: "30px" }}
    >
      <h1 style={{ fontSize: "2.6em", color: "white" }}>
        What do I <span className="purple"> Do </span> ?
      </h1>
      <Container>
        <div style={{ position: "relative", top: "-40px" }}>
          {HomePage.sections.map((section) => {
            return (
              <Row key={section.title}>
                <Col md={8} className="home-about-description">
                  <h1 style={{ fontSize: "2.6em" }}>
                    <span className="purple"> {section.title} </span>
                  </h1>
                  <ul className="home-about-body">
                    {section.description.map((point) => {
                      return (
                        <>
                          <li>{point}</li>
                          <br />
                        </>
                      );
                    })}
                  </ul>
                </Col>
                <Col md={4} className="myAvtar">
                  <img
                    src={section.image}
                    className="img-fluid="
                    alt="avatar"
                    style={{ width: section.image_width }}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
        <Row style={{ paddingTop: "50px" }}>
          <Col md={12} className="home-about-social">
            <h1>Find me on</h1>
            <p>
              Feel free to <strong className="purple">connect </strong>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="mailto:kiran.bodhapati@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/KiranM27"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/kiranm27/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kiran_mohan_b/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
