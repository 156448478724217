import React from "react";
import { Col, Row } from "react-bootstrap";
import { DataScienceTools } from "../portfolio";

function DataScienceStack() {
  return (
    <div style={{ padding: "1.25rem" }}>
      <script src="https://code.iconify.design/2/2.0.3/iconify.min.js"></script>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        {DataScienceTools.map((tool) => {
          return (
            <Col xs={4} md={2} className="tech-icons">
              {tool.icon}
              <div class="tech-icons-text">{tool.title}</div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default DataScienceStack;
