import React, { useState } from "react";
import PropTypes from "prop-types";
import "./ExperienceCard.scss";

export default function ExperienceCard(props) {
  const [imageType, setImageType] = useState("image");

  function changeImageType() {
    if (props?.experience?.alt_image) {
      if (imageType === "image") {
        setImageType("alt_image");
      }

      if (imageType === "alt_image") {
        setImageType("image");
      }
    }
  }

  return (
    <div className="experience-font">
      <div
        className="experience-container"
        onMouseEnter={changeImageType}
        onMouseLeave={changeImageType}
      >
        <div
          className={
            props?.pseudo
              ? "experience-card pseudo-experience-card"
              : "experience-card"
          }
        >
          {!props?.pseudo ? (
            <div className="box">
              <div className="content">
                <div className="fab">
                  <img
                    alt="Company"
                    src={props.experience[imageType]}
                    width="60px"
                  ></img>
                </div>
                <span>
                  <h3>{props?.experience?.title}</h3>
                  <h4>{props?.experience?.time}</h4>
                </span>
                <p>{props?.experience?.description}</p>
                <a
                  href={props?.experience?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props?.experience?.company}
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

ExperienceCard.propTypes = {
  experience: PropTypes.shape({
    title: PropTypes.string,
    time: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    company: PropTypes.string,
    alt_image: PropTypes.string,
  }),
  pseudo: PropTypes.bool,
};
